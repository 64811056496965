const DEFAULT_THUMBNAIL_ORIGINAL_WIDTH = null;

export class ECardState {
  #isSlotVisible = {};
  #thumbnailSourceWidth = DEFAULT_THUMBNAIL_ORIGINAL_WIDTH;

  constructor(component) {
    this.requestRender = component.requestRender.bind(component);
  }

  get thumbnailSourceWidth() {
    return this.#thumbnailSourceWidth;
  }

  set thumbnailSourceWidth(value) {
    this.#thumbnailSourceWidth = parseInt(value, 10) || DEFAULT_THUMBNAIL_ORIGINAL_WIDTH;
    this.requestRender();
  }

  isThumbnailVisible() {
    return this.#isSlotVisible.thumbnail;
  }

  isHeaderVisible() {
    const conditions = [
      this.#isSlotVisible['header-start'],
      this.#isSlotVisible['header-end']
    ];

    return conditions.some(condition => condition);
  }

  isContentVisible() {
    return this.#isSlotVisible.content;
  }

  isActionsVisible() {
    return this.#isSlotVisible.actions;
  }

  isContentWrapperVisible() {
    return this.isContentVisible() || this.isActionsVisible();
  }

  isThumbnailWrapperVisible() {
    return this.isThumbnailVisible() || this.isHeaderVisible();
  }

  setSlotVisibility(slotName, isVisible) {
    this.#isSlotVisible[slotName] = isVisible;
    this.requestRender();
  }
};
