import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';

class EDatagridBulkGroup extends HTMLCustomElement {
  init() {
    this.state = {
      uuid: uuid(),
      type: '',
      icon: '',
      label: '',
      tooltip: '',
      disabled: false,
      disabledTooltip: '',
      layout: 'table'
    };

    this.events = {
      updateAction: this._updateAction.bind(this)
    };

    this.addEventListener('bulk-action.update', this.events.updateAction, true);
  }

  connectedCallback() {
    this._dispatchUpdateEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('bulk-group.delete', {
      detail: {
        uuid: this.state.uuid
      }
    }));
  }

  static observedAttributes = ['type', 'icon', 'label', 'tooltip', 'disabled', 'disabled-tooltip'];

  set type(value) {
    this.state.type = value;
    this._dispatchUpdateEvent();
  }

  set icon(value) {
    this.state.icon = value;
    this._dispatchUpdateEvent();
  }

  set label(value) {
    this.state.label = value;
    this._dispatchUpdateEvent();
  }

  set tooltip(value) {
    this.state.tooltip = value;
    this._dispatchUpdateEvent();
  }

  set disabled(value) {
    this.state.disabled = super._convertAttributeToBoolean(value);
    this._dispatchUpdateEvent();
  }

  set disabledTooltip(value) {
    this.state.disabledTooltip = value;
    this._dispatchUpdateEvent();
  }

  get layout() {
    return this._state.layout;
  }

  set layout(value) {
    this.state.layout = value;
    this._dispatchUpdateEvent();
  }

  _updateAction(event) {
    event.detail.group = this.state.uuid;
  }

  _dispatchUpdateEvent() {
    this.dispatchEvent(new CustomEvent('bulk-group.update', {
      bubbles: true,
      detail: { ...this.state }
    }));
  }
}

export default EDatagridBulkGroup;
