import { html } from 'uhtml';
import isMatch from 'lodash.ismatch';
import flipper from '../../../../../utils/flipper';
import EDatagridItemActionTemplate from './item-action.js';
import EDatagridLayoutCardEmptyStateTemplate from './emptystate.js';

export default class EDatagridLayoutCardViewBodyTemplate {

  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;
    this._itemActionTemplate = new EDatagridItemActionTemplate(this._state, this._events);
    this._cardEmptyStateTemplate = new EDatagridLayoutCardEmptyStateTemplate(this._state, this._events, this._refs);
  }

  create() {
    return html`
      <div class="e-datagrid__card_wrapper">
        ${this.createItemListWrapper()}
        ${this._cardEmptyStateTemplate.create()}
      </div>
    `;
  }

  createItemListWrapper() {
    const filteredColumnDefiniton = this.#getVisibleColumnDefinition();
    if (!filteredColumnDefiniton.length) {
      return html``;
    }

    return html`${this.createItemList()}`;
  }

  createItemWrapper({ itemData }) {
    const filteredColumnDefiniton = this.#getVisibleColumnDefinition();
    const field = filteredColumnDefiniton.find(field => field.type === 'thumbnail');

    return html`
      <e-card thumbnail-source-width=${field?.sourceWidth}>
        ${this.createItem({ itemData })}
      </e-card>
    `;
  }

  createContentField({ field, itemData, index }) {
    return html`
      <div class="e-datagrid__field" slot="content">
        ${ index === 0 ? this.checkbox(itemData) : ''}
        <e-tooltip content="${field.head}" placement="left">
          ${this.createField({ field, itemData })}
        </e-tooltip>
      </div>
    `;
  }

  createThumbnailField({ field, itemData }) {
    return html`
      <iframe
        slot="thumbnail"
        srcdoc="${itemData.formatted[field.contentKey]}"
      ></iframe>
    `;
  }

  createItemList() {
    if (flipper.isOn('ui_datagrid_groups') && this._state.groupsState.contentKey) {
      let lastGroup = null;

      return this._state.visibleContent.reduce((items, itemData) => {
        const currentGroup = itemData.raw[this._state.groupsState.contentKey];

        const displayGroupName = this._state.groupsState.getGroupLabel(currentGroup);
        const isNewGroup = displayGroupName !== lastGroup;

        if (isNewGroup) {
          items.push(html`<h3 class="e-datagrid__card_group">${displayGroupName}</h3>`);
        }

        items.push(this.createItemWrapper({ itemData }));

        lastGroup = displayGroupName;

        return items;
      }, []);
    } else {
      return this._state.visibleContent.map((itemData) => this.createItemWrapper({ itemData }));
    }
  }

  createItem({ itemData }) {
    return html`
      ${this.createFieldList({ itemData })}
      ${this.createItemActions({ itemData })}
    `;
  }

  createFieldList({ itemData }) {
    const filteredColumnDefinition = this.#getVisibleColumnDefinition();

    const thumbnailFields = filteredColumnDefinition
      .filter(field => field.type === 'thumbnail')
      .map(field => this.createThumbnailField({ field, itemData }));

    const contentFields = filteredColumnDefinition
      .filter(field => field.type !== 'thumbnail')
      .map((field, index) => this.createContentField({ field, itemData, index }));

    return [...thumbnailFields, ...contentFields];
  }

  createField({ field, itemData }) {
    return field.renderHtml ? html([itemData.formatted[field.contentKey]]) : itemData.formatted[field.contentKey];
  }

  createItemActions({ itemData }) {
    const itemActions = this._state.itemActions
      .filter(itemAction => itemAction.layout === 'card')
      .filter(itemAction => !(itemAction.visibleKey && !(itemData.formatted[itemAction.visibleKey])))
      .map(itemAction => this._itemActionTemplate.create(itemAction, itemData));

    return itemActions;
  }

  checkbox(rowData) {
    if (!this._state.hasBulkCheckboxes) { return; }

    const isInSelection = this._state.bulkState.selection.some(selectedItem => isMatch(rowData.raw, selectedItem));
    const isSelectionInverted = this._state.bulkState.isSelectionInverted;
    const isChecked = (isInSelection && !isSelectionInverted) || (!isInSelection && isSelectionInverted);

    return html`
      <e-checkbox
        data-testid="card.checkbox"
        ?checked=${isChecked}
        ?disabled=${this._state.serverState.isLoading}
        @change=${(event) => this._events.onBulkSelectRowCheckboxChange(event, rowData)}
      ></e-checkbox>
    `;
  };

  #getVisibleColumnDefinition() {
    return this._state.columnDefinitions
      .filter(field => !this._state.columnSettingsState.activeSettings[field.contentKey].hidden);
  }
}
