import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';

class EDatagridBulkToggle extends HTMLCustomElement {
  init() {
    this.state = {
      uuid: uuid(),
      label: '',
      icon: '',
      layout: 'table'
    };
  }

  connectedCallback() {
    this._dispatchUpdateEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('bulk-toggle.delete', {
      detail: {
        uuid: this.state.uuid
      }
    }));
  }

  static observedAttributes = ['label', 'icon'];

  set label(value) {
    this.state.label = value;
    this._dispatchUpdateEvent();
  }

  set icon(value) {
    this.state.icon = value;
    this._dispatchUpdateEvent();
  }

  get layout() {
    return this.state.layout;
  }

  set layout(value) {
    this.state.layout = value;
    this._dispatchUpdateEvent();
  }

  _dispatchUpdateEvent() {
    this.dispatchEvent(new CustomEvent('bulk-toggle.update', {
      bubbles: true,
      detail: this.state
    }));
  }
}

export default EDatagridBulkToggle;
