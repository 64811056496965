import flipper from '../../../../../utils/flipper/index.js';
import purifier from '../../../../../utils/dom-purify.js';

const sanitizeColumn = (content, columnDefinitions) => {
  columnDefinitions.forEach(columnDefinition => {
    content.forEach(row => {
      if (!columnDefinition.renderHtml) { return; }

      row.formatted[columnDefinition.contentKey] = purifier.sanitize(
        row.formatted[columnDefinition.contentKey],
        { ALLOW_UNKNOWN_PROTOCOLS: true }
      );
    });
  });

  return content;
};

const sanitize = (content, [columnDefinitions]) => {
  if (flipper.isOn('ui_datagrid_card_layout')) {
    sanitizeColumn(content.content, columnDefinitions);
  }

  return content;
};

export default sanitize;
