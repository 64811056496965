import autoBind from 'auto-bind';

export class ESuggestEvents {
  #refs;
  #utils;
  #state;
  #open;
  #close;
  #component;
  #updateActionlistItems;

  constructor(component) {
    autoBind(this);
    this.#open = component.open.bind(component);
    this.#close = component.close.bind(component);

    this.#updateActionlistItems = component.updateActionlistItems.bind(component);

    this.#component = component;
    this.#refs = component.refs;
    this.#utils = component.utils;
    this.#state = component.state;

  }

  onOptionInsert(event) {
    event.target.removeEventListener('suggestinputoption.delete', this.onOptionDelete);
    event.target.addEventListener('suggestinputoption.delete', this.onOptionDelete);

    this.#state.insertOption(event.detail);
    this.#updateActionlistItems();
  }

  onOptionDelete(event) {
    this.#state.deleteOption(event.detail);
    this.#updateActionlistItems();
  }

  onOptionUpdate(event) {
    this.#state.updateOption(event.detail);
    this.#updateActionlistItems();
  }

  onClick() {
    this.#open();
  }

  onInput(event) {
    event.stopPropagation();

    this.#state.value = event.target.value;
    this.#updateActionlistItems();
    this.#dispatchInputEvent();
    this.#open();
  }

  onChange(event) {
    event.stopPropagation();
  }

  onActionlistChange(event) {
    event.stopPropagation();

    this.#state.value = event.detail.value;
    this.#updateActionlistItems();
    this.#dispatchChangeEvent();
    this.#close();
  }

  onUseValueClick() {
    this.#dispatchChangeEvent();
    this.#close();
  }

  onKeydown(event) {
    if (event.code === 'Escape' || event.code === 'Tab') {
      this.#close();
      return;
    }

    if (event.code === 'ArrowDown') {
      event.preventDefault();
      this.#open();

      if (this.#state.isUseValueButtonActive) {
        this.#state.isUseValueButtonActive = false;
        this.#refs.actionlist.moveActiveItem('first');
        return;
      }

      if (!this.#refs.actionlist.isLastItemActive) {
        this.#refs.actionlist.moveActiveItem('next');
        return;
      }

      if (!this.#state.isUseValueButtonDisabled) {
        this.#state.isUseValueButtonActive = true;
      } else {
        this.#refs.actionlist.moveActiveItem('first');
      }
    }

    if (event.code === 'ArrowUp') {
      event.preventDefault();
      this.#open();

      if (this.#state.isUseValueButtonActive) {
        this.#state.isUseValueButtonActive = false;
        this.#refs.actionlist.moveActiveItem('last');
        return;
      }

      if (!this.#refs.actionlist.isFirstItemActive) {
        this.#refs.actionlist.moveActiveItem('previous');
        return;
      }

      if (!this.#state.isUseValueButtonDisabled) {
        this.#state.isUseValueButtonActive = true;
      } else {
        this.#refs.actionlist.moveActiveItem('last');
      }
    }

    if (event.code === 'Enter' && this.#utils.popup.isOpened) {
      if (this.#state.isUseValueButtonActive) {
        this.onUseValueClick();
      } else {
        this.#refs.actionlist.toggleActiveItem();
        this.#close();
      }
    }
  }

  #dispatchInputEvent() {
    this.#component.dispatchEvent(new CustomEvent('input', {
      detail: this.#state.value,
      bubbles: true
    }));
  }

  #dispatchChangeEvent() {
    this.#component.dispatchEvent(new CustomEvent('change', {
      detail: this.#state.value,
      bubbles: true
    }));
  }
};
