import isMatch from 'lodash.ismatch';
import JSLogger from '../../../../utils/jslogger/index.js';

const logger = new JSLogger('datagrid');

class EDatagridBulkState {
  constructor(coreState) {
    this._coreState = coreState;
    this.requestRender = this._coreState.requestRender;

    this._selection = [];
    this._isSelectionInverted = false;
    this._bulkActions = [];
    this._bulkGroups = [];
    this._bulkToggles = [];
    this._isBulkToggleActive = false;
    this._isBulkWithServerLogged = false;
    this._serverSelectAllVisible = false;
  }

  get selection() {
    return this._selection;
  }

  set selection(value) {
    this._selection = value.map(input => this._coreState.content?.find(row => isMatch(row, input)));
    this.requestRender();
  }

  get isSelectionInverted() {
    return this._isSelectionInverted;
  }

  set isSelectionInverted(value) {
    this._isSelectionInverted = value;
    this.requestRender();
  }

  get selectedItemsCount() {
    return this._isSelectionInverted ?
      this._coreState.paginationState.itemCount - this._selection.length :
      this._selection.length;
  }

  get hasSelectedItems() {
    return this.selectedItemsCount > 0;
  }

  get bulkActions() {
    return this._bulkActions.filter(action => action.layout === this._coreState.layout);
  }

  get hasBulkActions() {
    return this.bulkActions.length > 0;
  }

  get bulkToggle() {
    return this._bulkToggles.filter(toggle => toggle.layout === this._coreState.layout)[0];
  }

  get hasBulkToggle() {
    return this.hasBulkActions && !!this.bulkToggle;
  }

  get isBulkToggleActive() {
    return this.bulkToggle === null || this._isBulkToggleActive;
  }

  toggleBulkMode() {
    this._isBulkToggleActive = !this._isBulkToggleActive;
    this.requestRender();
  }

  clear() {
    this._selection = [];
    this._isBulkToggleActive = false;
  }

  get bulkActionsWithoutGroup() {
    return this.bulkActions.filter(action => !action.group);
  }

  get bulkGroups() {
    return this._bulkGroups.filter(group => group.layout === this._coreState.layout);
  }

  get isBulkWithServerLogged() {
    return this._isBulkWithServerLogged;
  }

  set isBulkWithServerLogged(value) {
    this._isBulkWithServerLogged = value;
    this.requestRender();
  }

  // TODO: add isServerSide to isServerSelectAllEnable?
  get isServerSelectAllEnabled() {
    return !!(this._serverSelectAllVisible && this._coreState.idKey);
  }

  set serverSelectAllVisible(value) {
    this._serverSelectAllVisible = value;
    this.requestRender();
  }

  upsertBulkAction(options) {
    const bulkActionIndex = this._bulkActions.findIndex(action => action.uuid === options.uuid);

    if (bulkActionIndex > -1) {
      this._bulkActions = this._bulkActions.slice()
        .map((action, i) => i === bulkActionIndex ? options : action);
    } else {
      this._bulkActions = this._bulkActions.concat(options);
    }

    this.logBulkWithServer();
    this.requestRender();
  }

  deleteBulkAction(uuid) {
    this._bulkActions = this._bulkActions.filter(action => action.uuid !== uuid);
    this.requestRender();
  }

  upsertBulkGroup(options) {
    const bulkGroupIndex = this._bulkGroups.findIndex(group => group.uuid === options.uuid);

    if (bulkGroupIndex > -1) {
      this._bulkGroups = this._bulkGroups.slice()
        .map((group, i) => i === bulkGroupIndex ? options : group);
    } else {
      this._bulkGroups = this._bulkGroups.concat(options);
    }

    this.requestRender();
  }

  deleteBulkGroup(uuid) {
    this._bulkGroups = this._bulkGroups.filter(group => group.uuid !== uuid);
    this.requestRender();
  }

  upsertBulkToggle(options) {
    const bulkToggleIndex = this._bulkToggles.findIndex(toggle => toggle.uuid === options.uuid);

    if (bulkToggleIndex > -1) {
      this._bulkToggles = this._bulkToggles.slice()
        .map((toggle, i) => i === bulkToggleIndex ? options : toggle);
    } else {
      this._bulkToggles = this._bulkToggles.concat(options);
    }

    this.requestRender();
  }

  deleteBulkToggle(uuid) {
    this._bulkToggles = this._bulkToggles.filter(toggle => toggle.uuid !== uuid);
    this.requestRender();
  }

  getGroupActions(group) {
    return this.bulkActions.filter((action) => !!action.group && action.group === group.uuid);
  }

  toggleSelection(rowData) {
    const rowIndex = this._selection.findIndex(selectedItem => isMatch(rowData, selectedItem));
    if (rowIndex !== -1) {
      this._selection = this._selection.filter((selection, index) => index !== rowIndex);
    } else {
      this._selection = [...this._selection, rowData];
    }

    this.requestRender();
  }

  logBulkWithServer() {
    if (!this._isBulkWithServerLogged && this._bulkActions.length && this._coreState.serverState.isServerSide) {
      this._isBulkWithServerLogged = true;
      logger.log('Bulk action in server side datagrid found!');
    }
  }

  updateSelection() {
    this._selection = this._selection.reduce((selection, current) => {
      const selectedItemInContent = this._coreState.content.find(row =>
        isMatch(current, row) || this._isMatchByIdKey(current, row)
      );

      if (selectedItemInContent) {
        selection.push(selectedItemInContent);
      } else if (this._coreState.serverState.isServerSide) {
        selection.push(current);
      }

      return selection;
    }, []);

    this.requestRender();
  }

  selectAll() {
    if (this._coreState.serverState.isServerSide && this.isServerSelectAllEnabled) {
      this._isSelectionInverted = true;
      this._selection = [];
    } else {
      this._selection = this._coreState.content.slice();
    }
    this.requestRender();
  }

  deselectAll() {
    this._selection = [];
    this._isSelectionInverted = false;

    this.requestRender();
  }

  selectVisible() {
    this._isSelectionInverted ?
      this._removeVisibleFromSelection() :
      this._addVisibleToSelection();
  }

  deselectVisible() {
    this._isSelectionInverted ?
      this._addVisibleToSelection() :
      this._removeVisibleFromSelection();
  }

  _addVisibleToSelection() {
    const newVisibleContent = this._coreState.visibleContent.map(row => row.raw);
    this._selection = [...new Set([...newVisibleContent, ...this._selection])];
    this.requestRender();
  }

  _removeVisibleFromSelection() {
    const visibleContent = this._coreState.visibleContent.map(visibleRow => visibleRow.raw);
    this._selection = this._selection.filter(row =>
      visibleContent.findIndex(visibleItem =>
        isMatch(row, visibleItem)) === -1);
    this.requestRender();
  }

  _isMatchByIdKey(valueA, valueB) {
    const idKey = this._coreState.idKey;
    if (!idKey) { return false; }

    return valueA[idKey] === valueB[idKey];
  }
}

export default coreState => new EDatagridBulkState(coreState);
