import { EDSComponent } from '@emartech/eds-utils-component';
import { AttributeUtils } from '@emartech/eds-utils-component';
import { render } from 'uhtml';

import { popupUtility } from '../../js/utils/popup';

import { ESuggestState } from './state';
import { ESuggestEvents } from './events';
import { ESuggestTemplate } from './template';

export class ESuggest extends EDSComponent {
  static componentName = 'suggest';

  init() {
    this.refs = {};
    this.utils = {};
    this.state = new ESuggestState(this);
    this.events = new ESuggestEvents(this);
    this.template = new ESuggestTemplate(this);

    this.refs.wrapper = this.template.createWrapper();
  }

  connectedCallback() {
    this.requestRender().then(() => {
      this.#createPopup();
      this.updateActionlistItems();
      this.insertAdjacentElement('afterbegin', this.refs.wrapper);
    });
  }

  disconnectedCallback() {
    this.requestRender.clear();

    this.#destroyPopup();
  }

  static get observedAttributes() {
    return ['value', 'options', 'disabled', 'placeholder', 'loading'];
  }

  get value() {
    return this.state.value;
  }

  set value(value) {
    this.state.value = value;
  }

  get options() {
    return this.state.options;
  }

  set options(value) {
    this.state.options = AttributeUtils.convertToArray(value);
    this.updateActionlistItems();
  }

  get placeholder() {
    return this.state.placeholder;
  }

  set placeholder(value) {
    this.state.placeholder = value;
  }

  get disabled() {
    return this.state.disabled;
  }

  set disabled(value) {
    this.state.disabled = AttributeUtils.convertToBoolean(value);
  }

  get loading() {
    return this.state.loading;
  }

  set loading(value) {
    this.state.loading = AttributeUtils.convertToBoolean(value);
  }

  open() {
    if (this.state.disabled) { return; }

    this.utils.popup.open();
  }

  close() {
    this.utils.popup.close();
  }

  focus() {
    this.refs.input.focus();
  }

  render() {
    render(this.refs.wrapper, this.template.createElement());
  }

  updateActionlistItems() {
    if (!this.refs.actionlist) { return; }

    this.refs.actionlist.items = this.state.itemsForActionlist;
  }

  #createPopup() {
    if (this.utils.popup !== null) {
      this.#destroyPopup();
    }

    this.utils.popup = popupUtility.createPopup(this.refs.input, this.refs.popup, {
      matchOpenerWidth: true,
      itemToFocusOnClose: this.refs.input
    });
  }

  #destroyPopup() {
    this.utils.popup?.destroy({ preventAutoFocus: true });
    this.utils.popup = null;
  }
};
