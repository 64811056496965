export class State {
  #value = new Set();
  #options = [];
  #disabled = false;
  #loading = false;
  #placeholder = null;

  constructor(component) {
    this.requestRender = component.requestRender.bind(component);
  }

  get value() {
    return this.#value;
  }

  set value(value) {
    this.#value = new Set(value);
    this.requestRender();
  }

  get valueArray() {
    return [...this.#value];
  }

  get options() {
    return this.#options;
  }

  set options(value) {
    this.#options = value;
    this.requestRender();
  }

  get disabled() {
    return this.#disabled;
  }

  set disabled(value) {
    this.#disabled = value;
    this.requestRender();
  }

  get loading() {
    return this.#loading;
  }

  set loading(value) {
    this.#loading = value;
    this.requestRender();
  }

  get placeholder() {
    return this.#placeholder;
  }

  set placeholder(value) {
    this.#placeholder = value;
    this.requestRender();
  }

  addItem(item) {
    this.#value.add(item);
    this.requestRender();
  }

  deleteItem(item) {
    this.#value.delete(item);
    this.requestRender();
  }
};
