import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';
import { AttributeUtils } from '@emartech/eds-utils-component';

class EDatagridColumn extends HTMLCustomElement {
  init() {
    this._onColumnItemsDeleteCallback = this._onColumnItemsDeleteCallback.bind(this);

    this._state = {
      uuid: uuid(),
      contentKey: '',
      head: '',
      type: '',
      noWrap: false,
      width: undefined,
      renderHtml: false,
      sortable: true,
      tooltip: '',
      order: undefined,
      defaultWidth: 'auto',
      searchExclude: false,
      hidden: false,
      layout: 'table',
      isParent: false,
      itemsUuid: ''
    };
  }

  connectedCallback() {
    this.addEventListener('column-items.update', event => {
      this._state.isParent = true;
      this._state.itemsUuid = event.detail.uuid;
      this._dispatchEvent();
      event.stopPropagation();
      event.target.addEventListener('column-items.delete', this._onColumnItemsDeleteCallback);
    });
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('column.delete', {
      detail: {
        uuid: this._state.uuid
      }
    }));
  }

  static get observedAttributes() {
    return [
      'content-key', 'head', 'width', 'no-wrap', 'render-html', 'sortable',
      'type', 'tooltip', 'order', 'default-width', 'search-exclude', 'hidden'
    ];
  }

  set contentKey(value) {
    this._state.contentKey = value;
    this._dispatchEvent();
  }

  set head(value) {
    this._state.head = value;
    this._dispatchEvent();
  }

  set width(value) {
    this._state.width = value;
    this._dispatchEvent();
  }

  set noWrap(value) {
    this._state.noWrap = this._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set renderHtml(value) {
    this._state.renderHtml = this._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set sortable(value) {
    this._state.sortable = this._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set type(value) {
    this._state.type = value;
    this._dispatchEvent();
  }

  set tooltip(value) {
    this._state.tooltip = value;
    this._dispatchEvent();
  }

  set order(value) {
    const parsedValue = parseInt(value);
    this._state.order = isNaN(parsedValue) ? undefined : parsedValue;
    this._dispatchEvent();
  }

  set defaultWidth(value) {
    this._state.defaultWidth = value;
    this._dispatchEvent();
  }

  set searchExclude(value) {
    this._state.searchExclude = AttributeUtils.convertToBoolean(value);
    this._dispatchEvent();
  }

  set hidden(value) {
    this._state.hidden = AttributeUtils.convertToBoolean(value);
    this._dispatchEvent();
  }

  set layout(value) {
    this._state.layout = value;
    this._dispatchEvent();
  }

  set itemsUuid(value) {
    this._state.itemsUuid = value;
    this._dispatchEvent();
  }

  _dispatchEvent() {
    if (!this.parentNode || !this._hasRequiredState()) {
      return;
    }

    this.dispatchEvent(new CustomEvent('column.update', {
      bubbles: true,
      detail: Object.assign({}, this._state)
    }));
  }

  _hasRequiredState() {
    return this._state.contentKey;
  }

  _onColumnItemsDeleteCallback() {
    this._state.isParent = false;
    this._state.itemsUuid = '';
    this._dispatchEvent();
  }
}

export default EDatagridColumn;
