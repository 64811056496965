import { html } from 'uhtml';
import translator from '../../js/utils/translator';
import classNames from 'clsx';

export class Template {
  #state;
  #events;
  #createReference;

  constructor(component) {
    this.#createReference = component.createReference.bind(component);
    this.#state = component.state;
    this.#events = component.events;
  }

  createElement() {
    const className = classNames('e-suggest-multiple-input__background', {
      'e-suggest-multiple-input__background-disabled': this.#state.disabled
    });

    return html`
      <div class="e-suggest-multiple-input">
        <div class="${className}" @click=${this.#events.onClick}></div>
        <div class="e-suggest-multiple-input__content">
          ${this.#state.valueArray.map(item => this.#createItem(item))}
          <e-suggest
            class="e-suggest-multiple-input__suggest"
            ref=${this.#createReference(['suggest'])}
            @change="${this.#events.onSuggestChange}"
            .options=${this.#state.options}
            ?disabled=${this.#state.disabled}
            ?loading=${this.#state.loading}
            placeholder=${this.#state.placeholder}
          ></e-suggest>
        </div>
      </div>
    `;
  }

  createWrapper() {
    return html.node`
      <div class="e-suggest-multiple-input__wrapper"></div>
    `;
  }

  #createItem(item) {
    return html`
      <div class="e-label e-margin-none">
        ${item}
        <e-tooltip content="${translator.translate('components.suggestMultipleInput.item.deleteTooltip')}">
          <button
            type="button"
            class="e-label__close"
            ?disabled=${this.#state.disabled}
            @click=${() => this.#events.onItemDeleteClick(item)}
          ></button>
        </e-tooltip>
      </div>
    `;
  }
};
