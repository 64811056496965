import { AttributeUtils, EDSComponent } from '@emartech/eds-utils-component';
import { render } from 'uhtml';

import { State } from './state';
import { Events } from './events';
import { Template } from './template';
import configStore from '../../js/utils/config-store';

export class ESuggestMultipleInput extends EDSComponent {
  static componentName = 'suggest-multiple-input';

  init() {
    this.refs = {};
    this.utils = {};
    this.state = new State(this);
    this.events = new Events(this);
    this.template = new Template(this);
    this._configChangeCallback = this.requestRender.bind(this);

    this.refs.wrapper = this.template.createWrapper();
  }

  connectedCallback() {
    configStore.subscribe(this._configChangeCallback);
    this.insertAdjacentElement('afterbegin', this.refs.wrapper);

    this.requestRender();
  }

  disconnectedCallback() {
    configStore.unsubscribe(this._configChangeCallback);
    this.requestRender.clear();
  }

  static get observedAttributes() {
    return ['value', 'options', 'disabled', 'loading', 'placeholder'];
  }

  set value(value) {
    this.state.value = AttributeUtils.convertToArray(value);
  }

  get value() {
    return [...this.state.value];
  }

  set options(value) {
    this.state.options = AttributeUtils.convertToArray(value);
  }

  get disabled() {
    return this.state.disabled;
  }

  set disabled(value) {
    this.state.disabled = AttributeUtils.convertToBoolean(value);
  }

  get loading() {
    return this.state.loading;
  }

  set loading(value) {
    this.state.loading = AttributeUtils.convertToBoolean(value);
  }

  get placeholder() {
    return this.state.placeholder;
  }

  set placeholder(value) {
    this.state.placeholder = AttributeUtils.convertToString(value);
  }

  render() {
    render(this.refs.wrapper, this.template.createElement());
  }
};
