import { EDSComponent } from '@emartech/eds-utils-component';
import uuid from '../../../utils/uuid/index.js';
import flipper from '../../../utils/flipper/index.js';


export class EDatagridColumnItems extends EDSComponent {
  static componentName = 'datagrid-column-items';

  uuid;

  connectedCallback() {
    this.uuid = uuid();
    this.addEventListener('column.update', this.#updateLayout);
    this.#dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('column-items.delete'));
  }

  #updateLayout(event) {
    if (flipper.isOff('ui_datagrid_two_lines')) {
      event.stopPropagation();
      return;
    }

    if (event.detail.itemsUuid) { return; }

    event.target.itemsUuid = this.uuid;
    event.stopPropagation();
  }

  #dispatchEvent() {
    if (flipper.isOff('ui_datagrid_two_lines')) { return; }

    this.dispatchEvent(new CustomEvent('column-items.update', {
      bubbles: true,
      detail: { uuid: this.uuid }
    }));
  }
}
