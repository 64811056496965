import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import configStore from '../../../utils/config-store';
import floatUtility from '../../../utils/float';
import purifier from '../../../utils/dom-purify';
import JSLogger from '../../../utils/jslogger';
import flipper from '../../../utils/flipper/index.js';

import { EDialogState } from './state';
import { EDialogEvents } from './events';
import { EDialogTemplate } from './template';

const logger = new JSLogger('dialog');

class EDialog extends HTMLCustomElement {
  // Lifecycle events
  init() {
    super.watchForAddedChildNodes();

    this.refs = {};
    this.state = new EDialogState(this);
    this.events = new EDialogEvents(this);
    this.template = new EDialogTemplate(this);

    this.refs.dialog = this.template.createElement();
    this.refs.customHeader = {
      original: null,
      temp: null
    };
    this.refs.originalContentContainer = document.createElement('div');

    this.state.updateTranslations();
    configStore.subscribe(this._onConfigUpdate.bind(this));
  }

  childrenChangedCallback() {
    if (flipper.isOn('ui_dialog_move_content_fix')) {
      if (this.state.visible) {
        this._buildContent();
      }
    } else {
      if (this.state.opened) {
        this._buildContent();
      }
    }

    if (!this.state.cache) { return; }

    this._preloadToBody();
  }

  disconnectedCallback() {
    this.events.onDisconnect();
  }

  // Attributes
  static get observedAttributes() {
    return ['headline', 'no-close', 'no-keys', 'local', 'width', 'height', 'disable-scroll', 'cache', 'opened'];
  }

  set headline(value) {
    this.state.headline = value;
    this._renderHeader();
  }

  set noClose(value) {
    this.state.noClose = this._convertAttributeToBoolean(value);
    this._renderHeader();
  }

  set opened(value) {
    if (flipper.isOn('ui_dialog_move_content_fix')) {
      this._convertAttributeToBoolean(value) ? this.events.onOpen() : this.events.onClose();
    } else {
      this.state.opened = this._convertAttributeToBoolean(value);
      this.state.opened ? this.events.onOpen() : this.events.onClose();
    }
  }

  set preventClose(value) {
    this.state.preventClose = this._convertAttributeToBoolean(value);
  }

  set noKeys(value) {
    this.state.noKeys = this._convertAttributeToBoolean(value);
  }

  set local(value) {
    this.state.local = this._convertAttributeToBoolean(value);
  }

  set width(value) {
    this.state.width = value;
  }

  set height(value) {
    this.state.height = value;
  }

  set disableScroll(value) {
    this.state.disableScroll = this._convertAttributeToBoolean(value);
  }

  get contentNode() {
    return this.refs.content;
  }

  set cache(value) {
    this.state.cache = this._convertAttributeToBoolean(value);

    if (this.state.cache) {
      this._preloadToBody();
    }
  }

  // Actions
  open(options) {
    this.events.onOpen(options);
  }

  close() {
    this.events.onClose();
  }

  setSize(size) {
    const maxWidth = size?.width || this.state.width;
    const height = size?.height || this.state.height;

    if (this.refs.dialog.classList.contains('e-dialog-img')) {
      this.refs.container.style.width = 'auto';
      this.refs.container.style.maxWidth = '100%';
      this.refs.content.style.maxWidth = maxWidth;
    } else {
      this.refs.container.style.width = '100%';
      this.refs.container.style.maxWidth = maxWidth;
      this.refs.content.style.maxWidth = 'none';
    }

    this.refs.container.style.height = height;
  }

  setContent(content) {
    if (!this._isOriginalContentSaved()) {
      this._moveContent(this.refs.content, this.refs.originalContentContainer);
    } else {
      this._darklaunchSanitization(content);
      this.refs.content.innerHTML = '';
    }

    this.appendContent(content);
  }

  appendContent(content) {
    if (typeof content === 'string') {
      this._darklaunchSanitization(content);
      this.refs.content.innerHTML += content;
    } else {
      this.refs.content.appendChild(content);
    }
  }

  setHeader(content) {
    const currentCustomHeader = this.refs.container.querySelector('e-dialog-header');

    if (currentCustomHeader) {
      this.refs.container.removeChild(currentCustomHeader);
    }

    const dialogHeaderElement = document.createElement('e-dialog-header');
    if (typeof content === 'string') {
      dialogHeaderElement.innerHTML = content;
    } else {
      dialogHeaderElement.appendChild(content);
    }
    this.refs.header.insertAdjacentElement('afterend', dialogHeaderElement);
    this.refs.customHeader.temp = dialogHeaderElement;
  }

  // Rendering
  _renderHeader() {
    this.state.coverImage = this.querySelector('e-dialog-cover')?.getAttribute('src');
    this.state.coverImageHeight = this.querySelector('e-dialog-cover')?.getAttribute('height');
    this.state.isLightCoverTone = this.querySelector('e-dialog-cover')?.getAttribute('tone') === 'dark';

    render(this.refs.header, this.template.createHeaderContent());
    this._renderCustomHeader();
    this._renderHeaderBackButton();
    this._renderHeadlineSlot();

    this.refs.header.style.backgroundImage = this.state.coverImage ? `url("${this.state.coverImage}")` : null;
    this.refs.header.style.height = this.state.coverImageHeight || null;
    this.refs.header.classList.toggle('e-dialog__header-cover', !!this.state.coverImage);
    this.refs.header.classList.toggle('e-dialog__header-light', !!this.state.isLightCoverTone);
  }

  _renderCustomHeader() {
    const customHeader = this.querySelector('e-dialog-header');

    if (customHeader) {
      this.refs.customHeader.original = customHeader;
      this.refs.header.insertAdjacentElement('afterend', this.refs.customHeader.original);
    }
  }

  _renderHeaderBackButton() {
    const headerBackButton = this.querySelector('e-dialog-back-button');

    if (headerBackButton) {
      this.refs.headerBackButton = headerBackButton;
      this.refs.headerBackButton.classList.add('e-dialog__header_actions');
      this.refs.header.insertAdjacentElement('afterbegin', this.refs.headerBackButton);

      render(this.refs.headerBackButton, this.template.createHeaderBackButton());
    }
  }

  _renderContent() {
    if (this.state.content) {
      if (flipper.isOn('ui_dialog_move_content_fix')) {
        if (typeof this.state.content === 'string') {
          this._darklaunchSanitization(this.state.content);
          this.refs.content.innerHTML = this.state.content;
        } else {
          this.refs.content.appendChild(this.state.content);
        }

        return;
      } else {
        if (typeof this.state.content === 'string') {
          this._darklaunchSanitization(this.state.content);
          this.innerHTML = this.state.content;
        } else {
          this.appendChild(this.state.content);
        }
      }
    }

    this._moveContent(this, this.refs.content);
  }

  _renderFooter() {
    if (!this.refs.legacyCustomFooter) {
      const legacyCustomFooter = this.querySelector('.e-dialog__footer');

      this.state.hasLegacyCustomFooter = !!legacyCustomFooter;
      this.refs.legacyCustomFooter = legacyCustomFooter;
    }

    if (!this.refs.footer) {
      this.refs.footer = this.refs.legacyCustomFooter || this.template.createFooter();

      if (this.refs.footer) {
        this.refs.container.insertAdjacentElement('beforeend', this.refs.footer);
      }
    }

    this._renderCustomFooter();
  }

  _renderCustomFooter() {
    if (this.state.hasLegacyCustomFooter) { return; }

    if (!this.refs.customFooter) {
      const customFooter = this.querySelector('e-dialog-footer');

      this.state.hasCustomFooter = !!customFooter;
      this.refs.customFooter = customFooter;
    }

    if (!this.refs.customFooter || this.refs.customFooterTemplate) { return; }

    const customFooterSlot = this.refs.customFooter.querySelector('e-dialog-footer-slot');
    const customFooterActions = this.refs.customFooter.querySelector('e-dialog-footer-actions');

    this.refs.customFooterTemplate = this.template.createCustomFooter({
      slot: customFooterSlot,
      actions: customFooterActions
    });

    if (!!customFooterSlot) {
      this.refs.customFooterSlot?.appendChild(customFooterSlot);
    }

    if (!!customFooterActions) {
      this.refs.customFooterActions?.appendChild(customFooterActions);
    }

    this.refs.container.insertAdjacentElement('beforeend', this.refs.customFooterTemplate);
  }

  _renderHeadlineSlot() {
    if (!!this.state.hasHeadlineSlot) { return; }

    const customHeadlineSlot = this.querySelector('e-dialog-headline-slot');
    this.state.hasHeadlineSlot = !!customHeadlineSlot;
    this.refs.customHeadlineSlot = customHeadlineSlot;

    if (!this.state.hasHeadlineSlot) { return; }

    this.refs.headlineSlot.insertAdjacentElement('beforeend', this.refs.customHeadlineSlot);
  }

  // Private methods
  _onConfigUpdate() {
    this.events.onConfigChange();

    render(this.refs.header, this.template.createHeaderContent());

    if (!this.refs.headerBackButton) { return; }

    render(this.refs.headerBackButton, this.template.createHeaderBackButton());
  }

  _preloadToBody() {
    this._buildContent();
    this.refs.dialog.classList.add('e-dialog');
  }

  _moveContent(fromElement, toElement) {
    const childNodesLength = fromElement.childNodes.length;

    if (!childNodesLength) {
      return;
    }

    for (let i = 0; i < childNodesLength; ++i) {
      toElement.appendChild(fromElement.childNodes[0]);
    }
  }

  _restoreHeader() {
    if (this.refs.customHeader.original) {
      this.insertAdjacentElement('afterbegin', this.refs.customHeader.original);
      this.refs.customHeader.original = null;
    }

    if (this.refs.customHeader.temp) {
      this.refs.container.removeChild(this.refs.customHeader.temp);
      this.refs.customHeader.temp = null;
    }
  }

  _restoreHeaderBackButton() {
    if (!this.refs.headerBackButton) { return; }

    this.insertAdjacentElement('afterbegin', this.refs.headerBackButton);
  }

  _restoreFooter() {
    if (this.state.hasLegacyCustomFooter) {
      this.appendChild(this.refs.footer);
    } else if (this.refs.footer) {
      this.refs.container.removeChild(this.refs.footer);
    }

    this.refs.legacyCustomFooter = null;
    this.refs.footer = null;
  }

  _restoreCustomFooter() {
    if (this.state.hasCustomFooter) {
      const customFooter = this.querySelector('e-dialog-footer');

      if (this.refs.customFooterSlot) {
        customFooter.appendChild(this.refs.customFooterSlot);
      }

      if (this.refs.customFooterActions) {
        customFooter.appendChild(this.refs.customFooterActions);
      }

      this.refs.container.removeChild(this.refs.customFooter);

      this.refs.customFooter = null;
      this.refs.customFooterTemplate = null;
    }
  }

  _restoreHeadlineSlot() {
    if (!!this.state.hasHeadlineSlot) {
      this.insertAdjacentElement('afterbegin', this.refs.customHeadlineSlot);
      this.state.hasHeadlineSlot = false;
    }
  }

  _buildContent() {
    this._renderHeader();
    this._renderFooter();
    this._renderContent();

    if (this.refs.dialog.isConnected) { return; }

    if (this.state.local) {
      this.parentNode.appendChild(this.refs.dialog);
    } else {
      floatUtility.float(this.refs.dialog, this);
    }
  }

  _darklaunchSanitization(content) {
    const sanitizedContent = this._sanitize(content);
    if (content.length !== sanitizedContent.length) {
      logger.log('Sanitization altered content!');
    }
  }

  _sanitize(content) {
    return purifier.sanitize(content, { ADD_TAGS: ['iframe'] });
  }

  _restoreContent() {
    const content = this._isOriginalContentSaved() ? 'originalContentContainer' : 'content';
    this._moveContent(this.refs[content], this);
    this.refs.originalContentContainer.innerHTML = '';
    this.refs.content.innerHTML = '';

    floatUtility.remove(this.refs.dialog);
  }

  _isOriginalContentSaved() {
    return this.refs.originalContentContainer.children.length !== 0;
  }

  _elementHasTransition(element) {
    return window.getComputedStyle(element).transitionDuration !== '0s';
  }

  _setFocus() {
    const elementToFocus = this.refs.container;

    elementToFocus.tabIndex = -1;
    const autofocusNode = this.refs.dialog.querySelector('[data-autofocus]');

    if (autofocusNode) {
      autofocusNode.focus();
    } else {
      elementToFocus.focus();
    }
  }
}

export default EDialog;
