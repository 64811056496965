import { html } from 'uhtml';
import classNames from 'clsx';

export class ESuggestTemplate {
  #state;
  #events;
  #createReference;

  constructor(component) {
    this.#createReference = component.createReference.bind(component);
    this.#state = component.state;
    this.#events = component.events;
  }

  createElement() {
    return html`
      ${this.#createInputFieldElement()}
      ${this.#createPopupElement()}
    `;
  }

  createWrapper() {
    return html.node`
      <div class="e-suggest-input__wrapper"></div>
    `;
  }

  #createInputFieldElement() {
    return html`
      <input
        ref="${this.#createReference(['input'])}"
        type="text"
        .value=${this.#state.value}
        .placeholder=${this.#state.placeholder}
        ?disabled=${this.#state.disabled}
        @input=${this.#events.onInput}
        @click=${this.#events.onClick}
        @keydown=${this.#events.onKeydown}
        @change=${this.#events.onChange}
      />
    `;
  }

  #createPopupElement() {
    return html`
      <div ref="${this.#createReference(['popup'])}">
        ${this.#createActionlist()}
        ${this.#createFooter()}
      </div>
    `;
  }

  #createActionlist() {
    const className = classNames('', {
      'e-hidden': this.#state.isFilteredOptionsEmpty && !this.#state.loading
    });

    return html`
      <e-actionlist
        ref="${this.#createReference(['actionlist'])}"
        class="${className}"
        in-popover
        max-height="248px"
        search-hidden
        empty-state-text=""
        spacing="none"
        item-limit="0"
        ?loading=${this.#state.loading}
        ?always-show-active=${!this.#state.isUseValueButtonActive}
        @change=${this.#events.onActionlistChange}
      ></e-actionlist>
    `;
  }

  #createFooter() {
    const className = classNames('', {
      'e-popup-panel__footer': !this.#state.isFilteredOptionsEmpty || this.#state.loading
    });

    return html`
      <div class="${className}">
        <div class="e-popup-panel__actions">
          ${this.#createUseValueButton()}
        </div>
      </div>
    `;
  }

  #createUseValueButton() {
    const className = classNames('e-btn e-btn-borderless', {
      'e-btn-active': this.#state.isUseValueButtonActive
    });

    return html`
      <button
        class="${className}"
        ref="${this.#createReference(['button'])}"
        type="button"
        tabindex="-1"
        ?disabled=${this.#state.isUseValueButtonDisabled}
        @click=${this.#events.onUseValueClick}
      >
        <e-translation key="components.suggest.useButton">
        </e-translation>${this.#state.value ? ` '${this.#state.value}'` : ''}
      </button>
    `;
  }
};
