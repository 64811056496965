class EDatagridGroupsState {
  #coreState;
  #requestRender;

  #settings = {
    card: {},
    table: {}
  };

  #availableGroupKeys = [];

  constructor(coreState) {
    this.#coreState = coreState;
    this.#requestRender = this.#coreState.requestRender;
    this.resetState('table');
    this.resetState('card');
  }

  get settingsOnLayout() {
    return this.#settings[this.#coreState.layout];
  }

  get contentKey() {
    return this.settingsOnLayout.contentKey;
  }

  get groups() {
    return this.settingsOnLayout.groups;
  }

  get hasPredefinedGroups() {
    return !!this.settingsOnLayout.groups.length;
  }

  get predefinedGroups() {
    return this.hasPredefinedGroups ? this.groups.map(group => group.value) : [];
  }

  get availableGroupKeys() {
    return this.#availableGroupKeys;
  }

  updateAvailableGroupKeys() {
    let groups;

    if (this.hasPredefinedGroups) {
      groups = this.groups.map(group => group.value);
    } else if (this.contentKey) {
      groups = this.#coreState.content?.reduce((groups, currentRow) =>
        groups.add(currentRow[this.contentKey])
      , new Set());
    }

    this.#availableGroupKeys = groups ? [...groups] : [];
  }

  setState(state) {
    this.#settings[state.layout] = state;

    this.updateAvailableGroupKeys();

    this.#requestRender();
  }

  resetState(layout) {
    this.#settings[layout] = {
      contentKey: '',
      defaultLabel: null,
      groups: [],
      layout
    };

    this.updateAvailableGroupKeys();

    this.#requestRender();
  }

  getGroupLabel(value) {
    if (!this.hasPredefinedGroups) { return value; }

    const foundGroup = this.groups.find(group => group.value === value);

    if (foundGroup) {
      return foundGroup.label;
    }

    return this.settingsOnLayout.defaultLabel || this.#coreState.translations.groups.placeholder;
  }
}

export default coreState => new EDatagridGroupsState(coreState);
