import { html } from 'uhtml';
import classnames from 'clsx';
import flipper from '../../../../utils/flipper';

export default class EDatagridColumnHeaderTemplate {
  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;
  }

  create() {
    const hasColumnWithTitle = this._state.columnDefinitions.some(({ head }) => head);

    if (!hasColumnWithTitle) { return; }

    let headerContent;

    if (flipper.isOn('ui_datagrid_two_lines')) {
      headerContent = this._state.columnDefinitions
        .filter(field => !field.itemsUuid || field.isParent)
        .map(field => this._columnHeader(field));
    } else {
      headerContent = this._state.columnDefinitions.map(field => this._columnHeader(field));
    }

    return html`
      <thead>
        <tr class="e-table__titles">
          ${this._createControllerColumn()}
          ${headerContent}
          ${this._createItemActionColumn()}
        </tr>
      </thead>
    `;
  }

  _createControllerColumn() {
    if (!this._state.columnSettingsState.hasVisibleColumn) { return; }

    if (!this._state.hasBulkCheckboxes && !this._state.hasPriorityRadioButtons) { return; }

    return html`<th class="e-datagrid__controller_column_header" style="width: 1px;"></th>`;
  }

  _createItemActionColumn() {
    if (!this._state.itemActions.length || this._state.priorityState.isPriorityModeOpen) { return; }
    return html`<th></th>`;
  }

  _tooltip(field) {
    if (!field.tooltip) { return; }
    return html`<e-tooltip content="${field.tooltip}" type="helper" width="200"></e-tooltip>`;
  };

  _getFieldSorting(field) {
    if (this._state.filterState.sorting.key !== field.contentKey) { return 'none'; }

    let sorting = 'none';

    if (this._state.filterState.sorting.order === 'asc') {
      sorting = 'asc';
    } else if (this._state.filterState.sorting.order === 'desc') {
      sorting = 'desc';
    }

    return sorting;
  }

  _sortControls(field) {
    if (!this._state.priorityState.isSortableField(field)) { return; }

    const sorting = this._getFieldSorting(field);

    let icon = 'e-caret-vertical';

    switch (sorting) {
      case 'asc': icon = 'e-caret-up'; break;
      case 'desc': icon = 'e-caret-down'; break;
    }

    const hasSorting = this._state.filterState.sorting.key === field.contentKey;

    const classes = classnames(
      'e-btn e-btn-borderless e-btn-small e-btn-onlyicon e-datagrid__column_header_sort_button',
      { 'e-btn-active': hasSorting }
    );

    return html`
      <div class="e-datagrid__column_header_sort">
        <e-tooltip content="${this._state.translations.columnSortTooltip}">
          <button class="${classes}" type="button">
            <e-icon icon="${icon}" size="small">
          </button>
        </e-tooltip>
      </div>
    `;
  };

  _columnHeader(field) {
    const columnSettings = this._state.columnSettingsState.activeSettings[field.contentKey];
    const style = field.width ? `width:${field.width};` : null;
    const isRightAlignedColumn = ['number', 'percent', 'currency', 'duration'].includes(field.type);
    const classes = classnames('e-datagrid__column_header', {
      'e-hidden': columnSettings?.hidden && this._state.isHeaderVisible,
      'e-table__col-nowrap': field.noWrap,
      [`e-datagrid__column_header-${columnSettings?.width}`]: columnSettings?.width && columnSettings?.width !== 'auto',
      [`e-datagrid__column_header-${field.type}`]: !!field.type
    });

    this._refs.columnSettings[field.contentKey] = {};

    const titleClasses = classnames('e-datagrid__column_header_title', {
      'e-datagrid__column_header_title-right': isRightAlignedColumn
    });

    const sorting = this._getFieldSorting(field);

    let ariaSort = 'none';

    switch (sorting) {
      case 'asc': ariaSort = 'ascending'; break;
      case 'desc': ariaSort = 'descending'; break;
    }

    return html`
      <th
        style="${style}"
        class="${classes}"
        aria-sort="${ariaSort}"
        @click=${() => this._events.onColumnHeaderSortClick(field)}
      >
        <div class="e-datagrid__column_header_wrapper">
          ${isRightAlignedColumn ? this._createColumnSettings(field.contentKey) : this._sortControls(field)}
          <div class="${titleClasses}">
            ${field.head}
            ${this._tooltip(field)}
          </div>
          ${isRightAlignedColumn ? this._sortControls(field) : this._createColumnSettings(field.contentKey)}
        </div>
      </th>
    `;
  };

  _createColumnSettings(contentKey) {
    return html`
      <div class="e-datagrid__column_header_settings">
        ${this._createColumnSettingsButton(contentKey)}
        ${this._createColumnSettingsPopup(contentKey)}
      </div>
    `;
  }

  _createColumnSettingsButton(contentKey) {
    const buttonClasses = classnames(
      'e-btn e-btn-small e-btn-onlyicon e-btn-borderless e-datagrid__column_settings_button',
      { 'e-btn-active': this._state.columnSettingsState.isSettingsPanelVisible(contentKey) }
    );

    return html`
      <e-tooltip content="${this._state.translations.columnSettingsTooltip}">
        <button
          type="button"
          class="${buttonClasses}"
          ref="${node => this._refs.columnSettings[contentKey].button = node}"
          @click="${[event => this._events.onColumnSettingsButtonClick(event, contentKey), { capture: true }]}"
        >
          <e-icon icon="e-wrench" size="small"></e-icon>
        </button>
      </e-tooltip>
    `;
  }

  _createColumnSettingsPopup(contentKey) {
    return html`
      <div
        class="e-datagrid__column_settings_popup"
        ref="${node => this._refs.columnSettings[contentKey].popup = node}"
      >
        ${this._createColumnSettingsVisibility(contentKey)}
        ${this._createColumnSettingsWidthOptions(contentKey)}
        ${this._createColumnSettingsReset(contentKey)}
      </div>
    `;
  }

  _createColumnSettingsVisibility(contentKey) {
    if (!this._state.isHeaderVisible) {
      return;
    }

    return html`
      <e-actionlist
        class="e-datagrid__column_settings_visibility"
        selection-disabled
        item-limit="0"
        auto-focus
        @change="${event => this._events.onColumnSettingsVisibilityChange(event, contentKey)}"
      >
        <e-actionlist-group label="${this._state.translations.columnSettingsVisibilityLabel}">
          <e-actionlist-item>
            <e-icon icon="eye-slash" size="small" class="e-margin-right-xs"></e-icon>
            <e-translation key="components.datagrid.columnSettings.visibility.option"></e-translation>
          </e-actionlist-item>
        </e-actionlist-group>
      </e-actionlist>
    `;
  }

  _createColumnSettingsWidthOptions(contentKey) {
    const columnSettings = this._state.columnSettingsState.activeSettings[contentKey];
    return html`
      <e-actionlist
        class="e-datagrid__column_settings_width_options"
        item-limit="0"
        @change="${event => this._events.onColumnSettingsWidthChange(event, contentKey)}"
      >
        <e-actionlist-group label="${this._state.translations.columnSettingsWidthLabel}">
          <e-actionlist-item value="auto" ?selected="${!columnSettings?.width || columnSettings?.width === 'auto'}">
            <e-translation key="components.datagrid.columnSettings.width.auto"></e-translation>
          </e-actionlist-item>
          <e-actionlist-item value="narrow" ?selected="${columnSettings?.width === 'narrow'}">
            <e-translation key="components.datagrid.columnSettings.width.narrow"></e-translation>
          </e-actionlist-item>
          <e-actionlist-item value="wide" ?selected="${columnSettings?.width === 'wide'}">
            <e-translation key="components.datagrid.columnSettings.width.wide"></e-translation>
          </e-actionlist-item>
        </e-actionlist-group>
      </e-actionlist>
    `;
  }

  _createColumnSettingsReset(contentKey) {
    return html`
      <e-actionlist
        class="e-datagrid__column_settings_reset"
        selection-disabled
        item-limit="0"
        @change="${event => this._events.onColumnSettingsResetChange(event, contentKey)}"
      >
        <e-actionlist-group label="${this._state.translations.columnSettingsResetLabel}">
          <e-actionlist-item>
            <e-icon icon="reset-block" size="small" class="e-margin-right-xs"></e-icon>
            <e-translation key="components.datagrid.columnSettings.reset.option"></e-translation>
          </e-actionlist-item>
        </e-actionlist-group>
      </e-actionlist>
    `;
  }
}
