import EDatagridColumn from '../datagrid-column';

class EDatagridColumnThumbnail extends EDatagridColumn {
  init() {
    super.init();
    Object.assign(this._state, {
      type: 'thumbnail',
      sourceWidth: null,
      renderHtml: true
    });
  }

  static get observedAttributes() {
    return ['source-width'].concat(super.observedAttributes);
  }

  set renderHtml(_) { }

  set sourceWidth(value) {
    this._state.sourceWidth = value || null;
    this._dispatchEvent();
  }
}

export default EDatagridColumnThumbnail;
