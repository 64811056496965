import { EDSComponent } from '@emartech/eds-utils-component';
import { render } from 'uhtml';

import { ECardState } from './state';
import { ECardEvents } from './events';
import { ECardTemplate } from './template';

export class ECard extends EDSComponent {
  static componentName = 'card';

  init() {
    this.state = new ECardState(this);
    this.events = new ECardEvents(this);
    this.template = new ECardTemplate(this);

    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.requestRender();
  }

  static get observedAttributes() {
    return ['thumbnail-source-width'];
  }

  get thumbnailSourceWidth() {
    return this.state.thumbnailSourceWidth;
  }

  set thumbnailSourceWidth(value) {
    this.state.thumbnailSourceWidth = value;
  }

  render() {
    render(this.shadowRoot, this.template.createElement());

    if (this.state.thumbnailSourceWidth) {
      const scale = this.getBoundingClientRect().width / this.state.thumbnailSourceWidth;
      this.style.setProperty('--e-card-thumbnail-source-width', `${this.state.thumbnailSourceWidth}px`);
      this.style.setProperty('--e-card-thumbnail-scale', scale);
    } else {
      this.style.removeProperty('--e-card-thumbnail-source-width');
      this.style.removeProperty('--e-card-thumbnail-scale');
    }
  }
}
