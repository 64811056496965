import { dateUtils, formatNumber } from '@emartech/ui-framework-utils';
import { DurationFormatter } from '@emartech/eds-utils-formatter';
import flipper from '../../../../../utils/flipper/index.js';
import purifier from '../../../../../utils/dom-purify.js';

const formatAsString = value => {
  if (value === 0) { return '0'; };
  return value ? value.toString() : '';
};

const formatAsCurrency = (precision, currency, humanize, language, style) => {
  return formatNumber({ precision, humanize, style, currency: currency || 'EUR', language });
};

const formatAsTime = (value, formatType, formats) => {
  return dateUtils.formatByName(value, formatType, formats);
};

const formatAsLabels = value => {
  if (!Array.isArray(value)) { return value; }

  const labels = value.map(data => {
    if (typeof data === 'string') {
      return `<div class="e-label">${data}</div>`;
    }

    return `<div class="e-label e-label-${data.type}">${data.label}</div>`;
  });

  return labels.join('');
};

const getFormatter = (formats, column, language, translations) => {
  if (column.type === 'number') {
    return formatNumber({ precision: column.precision, style: 'decimal', humanize: column.humanize, language });
  } else if (column.type === 'percent') {
    return formatNumber({ precision: column.precision, style: 'percent' });
  } else if (['currency', 'currency-code'].includes(column.type)) {
    return formatAsCurrency(column.precision, column.currency, column.humanize, language, column.type);
  } else if (column.type === 'time') {
    return value => formatAsTime(value, column.format, formats);
  } else if (column.type === 'duration') {
    const durationFormatter = new DurationFormatter({
      pad: true, showZeroParts: true, precision: column.precision, translations: translations.duration
    });
    return value => durationFormatter.format(value);
  } else if (column.type === 'list') {
    return value => formatAsLabels(value);
  } else {
    return value => value;
  }
};

const formatColumn = (formats, language, translations) => (content, column) => {
  const format = getFormatter(formats, column, language, translations);
  content.forEach(row => {
    row.formatted[column.contentKey] = formatAsString(format(row.raw[column.contentKey]));

    if (flipper.isOff('ui_datagrid_card_layout')) {
      if (!column.renderHtml) { return; }

      row.formatted[column.contentKey] = purifier.sanitize(
        row.formatted[column.contentKey],
        { ALLOW_UNKNOWN_PROTOCOLS: true }
      );
    }
  });

  return content;
};

const format = (content, [language, columnDefinitions, formats, translations]) => {
  const newContent = content.map(({ raw }) => ({ raw, formatted: Object.assign({}, raw) }));
  return columnDefinitions.reduce(formatColumn(formats, language, translations), newContent);
};

export default format;
