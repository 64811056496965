import { dateUtils } from '@emartech/ui-framework-utils';
import { getTextContent } from './helpers.js';

const asNumber = value => parseFloat(value);
const asDate = value => {
  const parsedValue = dateUtils.parse(value);
  return dateUtils.isValid(parsedValue) ? parsedValue : null;
};
const asBoolean = value => value ? 1 : 0;

const converters = {
  number: asNumber,
  currency: asNumber,
  percent: asNumber,
  duration: asNumber,
  time: asDate,
  priority: asNumber,
  switch: asBoolean,
  html: value => value ? getTextContent(value).toLowerCase() : '',
  default: value => value ? value.toString().toLowerCase() : ''
};

const getValueConverter = (sortBy, columnDefinitions) => {
  const sortColumn = columnDefinitions.filter(definition => definition.contentKey === sortBy)[0];
  const sortType = sortColumn?.type;
  const isHTMLContent = sortColumn?.renderHtml;

  return converters[sortType] || (isHTMLContent ? converters.html : converters.default);
};

const isEmpty = value => !value && value !== 0;

const sortByTypeCallback = (converter, sortBy, order) => (actualRow, previousRow) => {
  const actual = converter(actualRow.raw[sortBy]);
  const previous = converter(previousRow.raw[sortBy]);

  if (actual === previous) { return 0; };
  if (isEmpty(actual)) { return 1; }
  if (isEmpty(previous)) { return -1; }

  return (actual > previous ? 1 : -1) * (order === 'asc' ? 1 : -1);
};

const sortGroups = (availableGroupKeys, hasPredefinedGroups) => {
  return hasPredefinedGroups ? availableGroupKeys : availableGroupKeys.sort();
};

const groupByKey = (content, { hasPredefinedGroups, groupKey, availableGroupKeys, order, sortBy, converter }) => {
  const grouped = {};
  const groupList = [];

  content.forEach(item => {
    const finalGroupKey = availableGroupKeys.includes(item.raw[groupKey]) ? item.raw[groupKey] : '';

    if (!grouped[finalGroupKey]) {
      grouped[finalGroupKey] = [];
      groupList.push(finalGroupKey);
    }

    grouped[finalGroupKey].push(item);
  });

  if (sortBy) {
    Object.keys(grouped).forEach(groupKey => {
      grouped[groupKey].sort(sortByTypeCallback(converter, sortBy, order));
    });
  }

  const sortedGroups = sortGroups(availableGroupKeys, hasPredefinedGroups);

  return sortedGroups
    .concat(groupList.filter(groupKey => groupKey === ''))
    .map(groupKey => grouped[groupKey]);
};

const sort = (content, [hasPredefinedGroups, availableGroupKeys, groupKey, sortBy, order, columnDefinitions]) => {
  if (!groupKey && !sortBy) { return content; }

  const converter = getValueConverter(sortBy, columnDefinitions);
  const groups = groupByKey([...content], {
    hasPredefinedGroups,
    groupKey,
    availableGroupKeys,
    order,
    sortBy,
    converter
  });

  return groups.filter(group => !!group).flat();
};

export default sort;
