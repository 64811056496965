import { EDSComponent } from '@emartech/eds-utils-component';
import flipper from '../../../utils/flipper/index.js';

export class EDatagridLayoutCard extends EDSComponent {

  static componentName = 'datagrid-layout-card';

  connectedCallback() {
    const watchedUpdateEvents = [
      'column.update',
      'empty-state.update',
      'error-state.update',
      'item-action.update',
      'action.update',
      'bulk-action.update',
      'bulk-group.update',
      'bulk-toggle.update',
      'groups.update'
    ];

    watchedUpdateEvents.forEach(updateEvent =>
      this.addEventListener(updateEvent, this.#updateLayout)
    );
  }

  #updateLayout(event) {
    if (flipper.isOff('ui_datagrid_card_layout')) {
      event.stopPropagation();
      return;
    }

    if (event.detail.layout === 'card') { return; }

    event.target.layout = 'card';
    event.stopPropagation();
  }
}
