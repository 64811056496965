import { HTMLCustomElement } from '@emartech/ui-framework-utils';

export class EDatagridErrorState extends HTMLCustomElement {
  init() {
    this._state = {
      layout: 'table'
    };
  }

  connectedCallback() {
    this._dispatchUpdateEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('error-state.delete'));
  }

  set layout(value) {
    this._state.layout = value;
    this._dispatchUpdateEvent();
  }

  _dispatchUpdateEvent() {
    this.dispatchEvent(new CustomEvent('error-state.update', {
      bubbles: true,
      detail: { ...this._state }
    }));
  }
}

export default EDatagridErrorState;
