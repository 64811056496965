import autoBind from 'auto-bind';

export class Events {
  #component;
  #refs;
  #utils;
  #state;

  constructor(component) {
    autoBind(this);

    this.#component = component;
    this.#refs = component.refs;
    this.#utils = component.utils;
    this.#state = component.state;
  }

  onSuggestChange(event) {
    this.#state.addItem(event.detail);
    this.#dispatchChangeEvent();
    event.target.value = '';
  }

  onItemDeleteClick(item) {
    this.#state.deleteItem(item);
  }

  onClick() {
    this.#refs.suggest.focus();
  }

  #dispatchChangeEvent() {
    this.#component.dispatchEvent(new CustomEvent('change', {
      detail: this.#state.valueArray,
      bubbles: true
    }));
  }
};
